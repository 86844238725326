<template>
  <div ref="infiniteScroll">
    <div v-if="state.loading">
      <slot name="loading">
        <div
          class="w-10 h-10 mx-auto animate-spin border-t-4 border-b-4 rounded-full"
        ></div>
      </slot>
    </div>
    <div v-else-if="state.completed && !state.noResults">
      <slot name="completed">
        <div class="text-center text-lg">{{ t('loaded') }}</div>
      </slot>
    </div>
    <div v-else-if="state.completed && state.noResults">
      <slot name="noResults">
        <div class="text-center text-lg">{{ t('no_results') }}</div>
      </slot>
    </div>
    <div v-else-if="!auto">
      <slot name="load-more">
        <button
          class="text-nav_color block p-2 border border-nav_color rounded mx-auto text-center text-lg"
          @click="loadMore"
        >
          تحميل المزيد
        </button>
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  heightPercentage: { type: Number, default: 0 },
  parentSelector: { type: String, default: null },
  infiniteId: { type: Number, default: 0 },
  debounce: { type: Number, default: 500 },
  hasInitialCachedMaterials: { type: Boolean, default: false },
  auto: { type: Boolean, default: true }
})

const emit = defineEmits(['load-more'])

const infiniteScroll = ref<HTMLElement | null>(null)
const parent = ref<Element | null>(null)
const { t } = useI18n()

const state = reactive({
  loading: false,
  completed: false,
  noResults: !props.hasInitialCachedMaterials
})

watch(
  () => props.infiniteId,
  () => reset()
)

onMounted(() => {
  addSensor()
})

onBeforeUnmount(() => {
  removeSensor()
})

const scrollSensor = useDebounce(() => {
  if (props.parentSelector) {
    parentScrollSensor()
  } else {
    windowScrollSensor()
  }
}, props.debounce)

function addSensor() {
  if (props.parentSelector) {
    parent.value = document.querySelector(props.parentSelector)
    parent.value?.addEventListener('scroll', parentScrollSensor)
    parentScrollSensor()
  } else {
    document.addEventListener('scroll', windowScrollSensor)
    windowScrollSensor()
  }
}

function removeSensor() {
  if (props.parentSelector) {
    parent.value?.removeEventListener('scroll', parentScrollSensor)
  } else {
    document?.removeEventListener('scroll', windowScrollSensor)
  }
}

function windowScrollSensor() {
  if (!props.auto) {
    return
  }
  if (infiniteScroll.value && !state.loading && !state.completed) {
    const elRect = infiniteScroll.value.getBoundingClientRect()
    const height = elRect.top + (elRect.height * props.heightPercentage) / 100
    if (height >= 0 && height <= window.innerHeight) {
      loadMore()
    }
  }
}

function parentScrollSensor() {
  if (!props.auto) {
    return
  }
  if (infiniteScroll.value && !state.loading && !state.completed) {
    const elRect = infiniteScroll.value.getBoundingClientRect()
    const parentRect = parent.value?.getBoundingClientRect()
    const top = elRect.top - (parentRect?.top || 0)
    const height = top + (elRect.height * props.heightPercentage) / 100
    if (height >= 0 && height <= (parentRect?.height || 0)) {
      loadMore()
    }
  }
}

function loadMore() {
  state.loading = true
  emit('load-more', { complete, loaded: load })
}

function load() {
  state.noResults = false
  state.loading = false
  scrollSensor()
}

function complete() {
  state.loading = false
  state.completed = true
  removeSensor()
}

function reset() {
  state.loading = false
  state.noResults = true
  if (state.completed) {
    state.completed = false
    addSensor()
  }
  scrollSensor()

  if (!props.auto) {
    loadMore()
  }
}
</script>
