import { defineDriver } from 'unstorage'

export type LoadState = 'load' | 'complete' | 'done'

export default function (
  offset: number,
  limit: number,
  fetch: (state: ScrollState) => Promise<ApiResponse<[]> | null>,
  manipulate: (data: []) => void,
  resetHandler: () => void
) {
  const state = reactive<ScrollState>({
    offset,
    limit,
    infiniteId: +new Date(),
    count: offset,
    fetched: (offset > 0)
  })

  const scrollFetch = async (): Promise<LoadState> => {
    if (state.fetched) {
      // Avoid Additional Fetching After SSR Is Done With No Results
      if (state.offset === 0) {
        return 'complete'
      }

      // Avoid Additional Fetching If Last Count is Less Than Limit
      /*         10   <   20 */
      if (state.count < state.limit) {
        return state.offset >= state.limit ? 'done' : 'complete'
      }
    }

    const data = await fetch(state)

    state.fetched = true
    state.count = 0

    if (data?.data?.length) {
      manipulate(data.data)
      state.offset += state.limit
      state.count = data.data.length
    }
    return state.count ? 'load' : 'complete'
  }

  async function loadMore($state: {
    loaded: () => void
    complete: () => void
  }) {
    const result = await scrollFetch()

    if (result === 'load') {
      $state.loaded()
    } else if (result === 'complete') {
      $state.complete()
    } else if (result === 'done') {
      $state.loaded()
      $state.complete()
    }
  }

  const reset = () => {
    resetHandler()
    state.offset = offset
    state.limit = limit
    state.count = 0
    state.fetched = false
    state.infiniteId = +new Date()
  }

  return {
    infiniteId: toRef(state, 'infiniteId'),
    scrollFetch,
    loadMore,
    reset
  }
}
